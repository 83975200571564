export function tilesProducts() {
  const sections = document.querySelectorAll('[data-tiles-products]');
  const mobileQuery = window.matchMedia('(max-width: 640px)');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  };

  function handleIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const section = entry.target;
        const images = section.querySelectorAll('[data-tiles-products-bg-image]');

        images.forEach((image) => {
          let imageUrl;

          if (mobileQuery.matches) {
            imageUrl = image.getAttribute('data-bg-mobile');
          } else {
            imageUrl = image.getAttribute('data-bg-desktop');
          }

          image.style.backgroundImage = `url('${imageUrl}')`;
        });
      }
    });
  }

  // init
  const observer = new IntersectionObserver(handleIntersection, options);
  sections.forEach((element) => observer.observe(element));
}
