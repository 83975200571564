export function alertBanner() {
  const alertBanner = document.querySelector('#site-alert-banner');
  const buttonTriggerOpen = document.querySelector('#site-alert-banner-trigger-open');
  const buttonTriggerClose = document.querySelector('#site-alert-banner-trigger-close');

  if (!alertBanner || !buttonTriggerOpen || !buttonTriggerClose) {
    return;
  }

  buttonTriggerOpen.addEventListener('click', () => {
    alertBanner.classList.remove('is-hidden');
    buttonTriggerOpen.classList.add('is-hidden');
  });

  buttonTriggerClose.addEventListener('click', () => {
    alertBanner.classList.add('is-hidden');
    buttonTriggerOpen.classList.remove('is-hidden');
  });
}
