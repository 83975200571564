export function setExternalLink() {
  const currentDomain = window.location.hostname;
  const anchors = document.querySelectorAll(`a:not([href*="${currentDomain}"]:not([href*="cdn.${currentDomain}"]), [href^="#"], .no-external-link)`);

  anchors.forEach((anchor) => {
    const href = anchor.href.toLowerCase();
    const isExternal = href.startsWith('http://') || href.startsWith('https://') || href.endsWith('.pdf') || href.endsWith('.docx');


    console.log('#### href:', href);
    console.log('isExternal:', isExternal);

    if (isExternal) {
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('data-external', 'true');
    }
  });
}

