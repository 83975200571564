import { formatPhone, validateField, validatePhones, validateRequiredFields } from '../../utils/form';
import { formCompletedEvent, formStartEvent, formSubmitEvent } from '../../utils/gtm';
import { getSessionInfo } from '../../utils/craft.js';

export default function jobForm() {
  const form = document.querySelector('#job-candidate-form');

  if (!form) return;

  const fileInputs = form.querySelectorAll('.base-form--input-file');
  const checkboxes = form.querySelectorAll('input[type="checkbox"]');
  const consent1 = form.querySelector('#gdpr_requires_processing_consent');
  const consent2 = form.querySelector('#gdpr_requires_retention_consent');
  const formContainer = form.closest('.form-container');

  let firstFocus = false;

  const startEvent = {
    event: 'user_interaction',
    event_name: 'form_start',
    interaction: {
      lead_type: 'job_form'
    }
  };

  if (consent2) {
    consent2.closest('.input-wrapper').classList.add('disabled');
  }

  if (consent1) {
    consent1.addEventListener('change', (e) => {
      if (e.target.checked) {
        consent2.closest('.input-wrapper').classList.remove('disabled');
      } else {
        consent2.closest('.input-wrapper').classList.add('disabled');
        consent2.checked = false;
      }
    });
  }

  fileInputs.forEach((element) => {
    element.addEventListener('change', (e) => {
      const file = e.target.files[0];
      const display = e.target.nextElementSibling;
      display.value = file ? file.name : '';
    });
    element.addEventListener('focus', (e) => {
      e.target.nextElementSibling.classList.add('focus');
      if (!firstFocus) {
        window.pushGTMEvent(formStartEvent(form));
        firstFocus = true;
      }
    });
    element.addEventListener('blur', (e) => {
      e.target.nextElementSibling.classList.remove('focus');
    });
  });

  checkboxes.forEach((element) => {
    element.addEventListener('change', (e) => {
      validateField(element);
    });
  });

  const inputs = form.querySelectorAll('input');
  inputs.forEach((element) => {
    element.addEventListener('blur', (e) => {
      if (element.value === '') return;
      validateField(element);
      if (element.type === 'tel') {
        element.value = formatPhone(element.value);
      }
    });
    element.addEventListener('focus', (e) => {
      if (!firstFocus) {
        window.pushGTMEvent(formStartEvent(form));
        firstFocus = true;
      }
    });
  });



  form.addEventListener('submit', (e) => {
    e.preventDefault();

    if (!firstFocus) {
      window.pushGTMEvent(formStartEvent(form));
      firstFocus = true;
    }



    if (typeof grecaptcha === 'undefined') {
      // eslint-disable-next-line no-undef
      grecaptcha = {};
    }
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute(window.recaptchaKey, { action: 'submit' }).then(function (token) {
        getSessionInfo()
          .then(session => {
            formContainer.classList.remove('error');
            formContainer.classList.add('submitting');
            const formData = new FormData(form);
            formData.append(window.csrfTokenName, session.csrfTokenValue);
            const valid = validateRequiredFields(form);
            const validPhones = validatePhones(form);
            formData.append('token', token);

            console.log('valid', valid, validPhones);
            if (!validateRequiredFields(form) || !validatePhones(form)) {
              formContainer.classList.remove('submitting');
              formContainer.classList.add('error');
              return;
            }
            console.log('jobForm send to grenhouse');
            fetch('/actions/website/jobs/post-job', { method: 'POST', body: formData })
              .then(function(response) {
                if (response.status != 200) {
                  throw response.status;
                } else {
                  return response.json();
                }
              })
              .then(function(data) {
                if (data.success) {

                  formContainer.classList.add('submitted');
                  formContainer.scrollIntoView({ behavior: 'smooth' });

                  window.pushGTMEvent(formSubmitEvent(form));
                  window.pushGTMEvent(formCompletedEvent(form));
                } else {
                  formContainer.classList.remove('submitting');
                  formContainer.classList.add('error');
                  const f = form.querySelector('#' + data.field);
                  let focus = f;
                  if (f) {
                    if (data.field.indexOf('-display') > -1) {
                      focus = form.querySelector('#' + data.field.replace('-display', ''));
                    }
                    f.classList.add('error');
                    focus.focus();
                  }
                }

                console.log('jobForm Response', data);
              })
              .catch(function(error) {
                formContainer.classList.remove('submitting');
                formContainer.classList.add('error');
                const text = document.querySelector('.error-message-text');
                text.innerHTML = error;

                console.log(error);
              });
          });
      });
    });
  });
}
