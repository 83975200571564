import { getSessionInfo } from '../../utils/craft.js';
import { formatPhone, validateField, validateRequiredFields } from '../../utils/form.js';
import { formCompletedEvent, formStartEvent, formSubmitEvent } from '../../utils/gtm.js';

export default function mobileWorldCongress2025MarchForm() {
  const form = document.querySelector('#mobile-world-congress-2025-march-page-form');

  if (!form) return;

  const formContainer = form.closest('.form-container');
  let firstFocus = false;

  const inputs = form.querySelectorAll('input, textarea');
  inputs.forEach((element) => {
    element.addEventListener('blur', (e) => {
      if (element.value === '') return;
      validateField(element);
      if (element.type === 'tel') {
        element.value = formatPhone(element.value);
      }
    });
    element.addEventListener('focus', (e) => {
      if (!firstFocus) {
        window.pushGTMEvent(formStartEvent(form));
        firstFocus = true;
      }
    });
  });

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    if (!firstFocus) {
      window.pushGTMEvent(formStartEvent(form));
      firstFocus = true;
    }

    if (typeof grecaptcha === 'undefined') {
      // eslint-disable-next-line no-undef
      grecaptcha = {};
    }

    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute(window.recaptchaKey, { action: 'submit' }).then(function (token) {
        getSessionInfo().then((session) => {
          const formData = new FormData(form);
          const valid = validateRequiredFields(form);

          formContainer.classList.remove('error');
          formContainer.classList.add('submitting');

          formData.append(window.csrfTokenName, session.csrfTokenValue);
          formData.append('token', token);

          if (!validateRequiredFields(form)) {
            formContainer.classList.remove('submitting');
            formContainer.classList.add('error');
            return;
          }

          fetch(form.action, { method: 'POST', body: formData })
            .then(function (response) {
              if (response.status != 200) {
                console.log('response:', response);
                throw response.status;
              } else {
                return response.json();
              }
            })
            .then(function (data) {
              if (data.success) {
                formContainer.classList.add('submitted');

                window.pushGTMEvent(formSubmitEvent(form));
                window.pushGTMEvent(formCompletedEvent(form));

                const headingElement = document.querySelector(
                  '#mobile-world-congress-2025-march-form .form-wrapper-content-left'
                );
                if (headingElement) {
                  headingElement.style.display = 'none';
                }
              } else {
                formContainer.classList.remove('submitting');
                formContainer.classList.add('error');
              }

              const elMain = document.getElementById('main');
              const elMainOffset = 0;
              const elMainPosition = elMain.getBoundingClientRect().top + window.scrollY;
              const elMainOffsetPosition = elMainPosition - elMainOffset;

              if (elMain) {
                window.scrollTo({
                  top: elMainOffsetPosition,
                  behavior: 'smooth'
                });
              }

              console.log('mobile-world-congress-2025-march-page-form Response', data);
            })
            .catch(function (error) {
              console.log('Error: ' + error);
            });
        });
      });
    });
  });
}
