import { validateField, validateRequiredFields, formatPhone } from '../../utils/form';
import { formStartEvent, formSubmitEvent, formCompletedEvent } from '../../utils/gtm';
import { getSessionInfo } from '../../utils/craft.js';

export default function accessibilityForm() {
	const form = document.querySelector('#accessibility-page-form');

	if (!form) return;

	const formContainer = form.closest('.form-container');
	let firstFocus = false;

  const checkboxInput = form.querySelector('.lightswitch input');
  const optionalFieldsWrapper = form.querySelector('.optional-fields');
  let requiredFieldsInOptionalContainer = [];
  const radioInputs = form.querySelectorAll('.radio-group input');
  const contactFieldsWrapper = form.querySelector('.contact-fields');

  const inputs = form.querySelectorAll('input:not([type="checkbox"]), textarea');
	inputs.forEach(element => {
		element.addEventListener('blur', (e) => {
			if(element.value === '') return;
			validateField(element);
			if(element.type === 'tel') {
				element.value = formatPhone(element.value);
			}
		});
		element.addEventListener('focus', (e) => {
			if(!firstFocus) {
				window.pushGTMEvent(formStartEvent(form));
				firstFocus = true;
			}
		});
	});

  checkboxInput.addEventListener('change', () => {
    if(checkboxInput.checked) {
      requiredFieldsInOptionalContainer = optionalFieldsWrapper.querySelectorAll('[required]');

      requiredFieldsInOptionalContainer.forEach(field => {
        field.removeAttribute('required');

        if(field.classList.contains('error')) {
          removeError(field);
        }
      });
    } else {
      requiredFieldsInOptionalContainer.forEach(field => {
        field.setAttribute('required', '');
      });
    }
  });

  radioInputs.forEach(radio => {
    radio.addEventListener('change', () => {
      let correspondingFieldWrapper = contactFieldsWrapper.querySelector('.wrapper#' + radio.id.replace('_', '-'));
      let otherFieldsWrappers = contactFieldsWrapper.querySelectorAll('.wrapper:not(#' + radio.id.replace('_', '-'));

      if(radio.checked) {
          showCorrespondingField(correspondingFieldWrapper);
          hideOtherFields(otherFieldsWrappers)
      }
    });
  });

  function showCorrespondingField(correspondingFieldWrapper) {
      let correspondingFieldInputs = correspondingFieldWrapper.querySelectorAll('input');

      correspondingFieldWrapper.style.display = 'block';
      correspondingFieldInputs.forEach(field => {
        field.setAttribute('required', '');
      });
  }

  function hideOtherFields(otherFieldsWrappers) {
      otherFieldsWrappers.forEach(wrapper => {
          let otherFieldsInputs = wrapper.querySelectorAll('input');

          wrapper.style.display = 'none';
          otherFieldsInputs.forEach(field => {
            field.removeAttribute('required');

              if(field.classList.contains('error')) {
                removeError(field);
              }
          })
      });
  }

  function removeError(field) {
    field.setAttribute('aria-invalid', 'false');
    field.classList.remove('error');
    field.closest('.input-control').classList.remove('has-error');
  }

	form.addEventListener('submit', (e) => {
		e.preventDefault();

		if(!firstFocus) {
			window.pushGTMEvent(formStartEvent(form));
			firstFocus = true;
		}

		if(typeof grecaptcha === 'undefined') {
			// eslint-disable-next-line no-undef
			grecaptcha = {};
		}

		// eslint-disable-next-line no-undef
		grecaptcha.ready(function() {
			// eslint-disable-next-line no-undef
			grecaptcha.execute(window.recaptchaKey, {action: 'submit'}).then(function(token) {
        getSessionInfo()
          .then(session => {
            formContainer.classList.remove('error');
            formContainer.classList.add('submitting');
            const formData = new FormData();
            formData.append('lang', form.querySelector('input#lang').value);
            formData.append('anonymous', form.querySelector('input#anonymous').checked);
            formData.append('contact_method', form.querySelector('.radio-group input:checked').value);
            const requiredFields = form.querySelectorAll('[required]');
            requiredFields.forEach(field => {
              formData.append(field.name, field.value);
            });
            formData.append(window.csrfTokenName, session.csrfTokenValue);
            formData.append('token', token);

            if (!validateRequiredFields(form)) {
              formContainer.classList.remove('submitting');
              formContainer.classList.add('error');
              return;
            }

            fetch(form.action, { method: 'POST', body: formData })
              .then(function(response) {
                if (response.status != 200) {
                  throw response.status;
                } else {
                  return response.json();
                }
              })
              .then(function(data) {
                if (data.success) {
                  formContainer.classList.add('submitted');
                  window.pushGTMEvent(formSubmitEvent(form));
                  window.pushGTMEvent(formCompletedEvent(form));
                } else {
                  formContainer.classList.remove('submitting');
                  formContainer.classList.add('error');
                }
              })
              .catch(function(error) {
                console.log("Error: " + error);
              });
          });
			});
		});
	});
}
