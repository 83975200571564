import gsap from 'gsap';

export function imageMaskTranslateX() {
  const items = document.querySelectorAll('.image-mask-translate-x');

  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

  items.forEach((item) => {
    const images = item.querySelectorAll('.image-mask-translate-x__image');

    images.forEach((image) => {
      gsap.to(image, {
        xPercent: -25,
        duration: 15,
        repeat: -1,
        yoyo: true,
        ease: 'none',
        yoyoEase: false
      });
    });
  });
}
