import * as focusTrap from 'focus-trap';
import gsap from 'gsap';

export function videoBlockToFullScreen() {
  const mm = gsap.matchMedia();
  const mediaQueryDesktop = window.matchMedia('(min-width: 1024px)');

  const html = document.querySelector('html');
  const block = document.querySelector('[data-block-player]');
  const isReduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  if (!block) {
    return;
  }

  const playerInline = block.querySelector('[data-block-player-player]');
  const playerInlineContainer = block.querySelector('[data-block-player-inline-container]');
  const playerInlineButton = block.querySelector('[data-block-player-video-button]');
  const modal = block.querySelector('[data-modal-player]');
  const modalPlayer = block.querySelector('[data-modal-player-player]');
  const modalButtonClose = block.querySelector('[data-modal-player-button-close]');
  const playerSubtitles = modalPlayer.querySelector('track');

  const focusTrapNav = focusTrap.createFocusTrap(modal, {
    onActivate: () => modal.classList.add('focustrap-on'),
    onDeactivate: () => modal.classList.remove('focustrap-on')
  });

  gsap.to(
    playerInlineContainer,
    { opacity: 1, y: 0, duration: isReduceMotion ? 0 : 0.4, delay: 0.8 }
  );

  if (playerInline && isReduceMotion) {
    playerInline.pause();
    playerInline.currentTime = 0;
  }

  // Functions
  // --------------------------------------------
  function playerInlineLoadVideo() {
    const sources = playerInlineContainer.querySelectorAll('video source');

    if (!playerInline || playerInline.classList.contains('video-loaded')) return;

    sources.forEach((source) => {
      source.src = source.dataset.src;
    });

    playerInline.load();
    playerInline.classList.add('video-loaded');
  }

  function showModal() {
    modal.classList.add('is-active');
    html.classList.add('no-scroll');
    setTimeout(() => {
      modalPlayer.currentTime = 0;
      modalPlayer.setAttribute('src', modalPlayer.dataset.src);
      playerSubtitles.setAttribute('src', playerSubtitles.dataset.src);
      modalPlayer.play();
      document.addEventListener('keydown', handleEscapeKey);
      focusTrapNav.activate();
    }, 1);
  }

  function hideModal() {
    setTimeout(() => {
      modalPlayer.pause();
      modal.classList.remove('is-active');
      html.classList.remove('no-scroll');
      focusTrapNav.deactivate();
      document.removeEventListener('keydown', handleEscapeKey);
    }, 1);
  }

  function handleEscapeKey(event) {
    if (event.key === 'Escape') {
      hideModal();
    }
  }

  // Init
  // --------------------------------------------
  mm.add(mediaQueryDesktop, () => {
    playerInlineLoadVideo();
  });

  playerInlineButton.addEventListener('click', (e) => {
    e.preventDefault();
    showModal();
  });

  modalButtonClose.addEventListener('click', (e) => {
    e.preventDefault();
    hideModal();
  });
}
