import gsap from 'gsap';
import * as focusTrap from 'focus-trap';

export function siteMobileNav() {

  const html = document.querySelector('html');
  const siteMobileNav = document.querySelector('#site-mobile-nav');
  const hamburgerClose = document.querySelector('#site-mobile-nav-hamburger-close');
  const hamburgerOpen = document.querySelector('#site-header-hamburger');

  let isMenuOpen = false;
  let viewport = getComputedStyle(document.documentElement).getPropertyValue('--site-viewport');

  if(!siteMobileNav || !hamburgerClose) {
    return;
  }

  const focusTrapNav = focusTrap.createFocusTrap(siteMobileNav, {
    onActivate: () => siteMobileNav.classList.add('focustrap-on'),
    onDeactivate: () => siteMobileNav.classList.remove('focustrap-on'),
  });

  function onShow() {
    html.classList.add('no-scroll');
    hamburgerOpen.setAttribute('aria-hidden', 'true');

    gsap.set(siteMobileNav, { x: 0, display: 'flex' });
    gsap.to(siteMobileNav, {
      opacity: 1,
      duration: 0.4,
      onComplete: () => {
        isMenuOpen = true;
        hamburgerClose.focus();
        focusTrapNav.activate();
      }
    });
  }

  function onHide() {
    hamburgerOpen.setAttribute('aria-hidden', 'false');

    gsap.to(siteMobileNav, {
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        html.classList.remove('no-scroll');
        gsap.set(siteMobileNav, { x: 9999, display: 'none' });
        isMenuOpen = false;
        hamburgerOpen.focus();
        focusTrapNav.deactivate();
      }
    });
  }

  document.body.addEventListener('siteMobileNav:show', function (event) {
    onShow();
  });

  document.body.addEventListener('siteMobileNav:hide', function (event) {
    onHide();
  });

  hamburgerClose.addEventListener('click', (event) => {
    event.preventDefault();
    onHide();
  });

  document.addEventListener('keydown', (e) => {
    if (e.key === "Escape") {
      onHide();
    }
  });
}
