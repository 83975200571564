import { validateField, validateRequiredFields, formatPhone } from '../../utils/form';
import { formStartEvent, formSubmitEvent, formCompletedEvent } from '../../utils/gtm';
import { getSessionInfo } from '../../utils/craft.js';

export default function partnerForm() {
	const form = document.querySelector('#partner-page-form');

	if (!form) return;

	const formContainer = form.closest('.form-container');
	let firstFocus = false;

	const inputs = form.querySelectorAll('input, textarea');
	inputs.forEach(element => {
		element.addEventListener('blur', (e) => {
			if(element.value === '') return;
			validateField(element);
			if(element.type === 'tel') {
				element.value = formatPhone(element.value);
			}
		});
		element.addEventListener('focus', (e) => {
			if(!firstFocus) {
				window.pushGTMEvent(formStartEvent(form));
				firstFocus = true;
			}
		});
	});


	form.addEventListener('submit', (e) => {
		e.preventDefault();

		if(!firstFocus) {
			window.pushGTMEvent(formStartEvent(form));
			firstFocus = true;
		}


		if(typeof grecaptcha === 'undefined') {
			// eslint-disable-next-line no-undef
			grecaptcha = {};
		}

		// eslint-disable-next-line no-undef
		grecaptcha.ready(function() {
			// eslint-disable-next-line no-undef
			grecaptcha.execute(window.recaptchaKey, {action: 'submit'}).then(function(token) {
        getSessionInfo()
          .then(session => {
            formContainer.classList.remove('error');
            formContainer.classList.add('submitting');
            const formData = new FormData(form);
            formData.append(window.csrfTokenName, session.csrfTokenValue);
            const valid = validateRequiredFields(form);
            formData.append('token', token);

            if (!validateRequiredFields(form)) {
              formContainer.classList.remove('submitting');
              formContainer.classList.add('error');
              return;
            }

            fetch(form.action, { method: 'POST', body: formData })
              .then(function(response) {
                if (response.status != 200) {
                  throw response.status;
                } else {
                  return response.json();
                }
              })
              .then(function(data) {
                if (data.success) {
                  formContainer.classList.add('submitted');

                  window.pushGTMEvent(formSubmitEvent(form));
                  window.pushGTMEvent(formCompletedEvent(form));
                } else {
                  formContainer.classList.remove('submitting');
                  formContainer.classList.add('error');
                  // const f = form.querySelector('#'+data.field);
                  // let focus = f;
                  // if(f) {
                  // 	if(data.field.indexOf('-display') > -1) {
                  // 		focus = form.querySelector('#'+data.field.replace('-display', ''));
                  // 	}
                  // 	f.classList.add('error');
                  // 	focus.focus();
                  // }
                }

                console.log("partnerForm Response", data);
              })
              .catch(function(error) {
                console.log("Error: " + error);
              });
          });
			});

		});


	});

}
